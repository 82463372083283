import Home from "./containers/Home";
require("./index.css");

function App() {
  return (
      <>
      <Home/>
      </>
  );
}

export default App;
